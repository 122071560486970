<template>
  <v-container fluid class="py-0">
    <Overview />
  </v-container>
</template>

<script>
import Overview from '@/components/organisms/Overview'

export default {
  name: 'Home',
  components: {
    Overview,
  },
}
</script>

<style lang="scss">
</style>
