var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{staticClass:"py-4"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(("" + (_vm.$t("TopGainers")))))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.gainerHeader,"items":_vm.getTopGainers,"loading":_vm.mutableLoading,"headers-length":12,"header-props":{ sortIcon: 'mdi-chevron-up' },"no-data-text":"No Record Found.","loader-height":1,"item-key":"cmc_rank","hide-default-footer":"","disable-pagination":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(item.gainer_rank))])]),_c('td',{staticClass:"text-center currency-details sticky secondary--text pointer",on:{"click":function($event){return _vm.onCurrencyView(item)}}},[_c('div',{staticClass:"d-flex pt-2"},[_c('div',[(item.logo === null)?_c('img',{staticClass:"currency-logo",attrs:{"alt":item.name,"src":require("@/assets/images/no-image.png")}}):_c('img',{staticClass:"currency-logo",attrs:{"alt":item.name,"lazy-src":item.logo,"src":item.logo},on:{"error":_vm.helper.imgerror}})]),_c('div',{staticClass:"ml-2"},[_c('span',[_vm._v(_vm._s(item.name))])])])]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"ml-1 greyText--text"},[_c('small',[_vm._v(_vm._s(item.symbol))])])]),_c('td',{staticClass:"text-right"},[(!_vm.mutableLoading)?_c('v-icon',{class:("mt-m-1px grey--text icon-" + (_vm.selected_currency.toLowerCase())),attrs:{"x-small":""}}):_vm._e(),(!_vm.mutableLoading)?_c('span',[_vm._v(_vm._s(_vm.helper.roundDecimalPlaces(item.price)))]):_c('span',{},[_vm._v("0.00")])],1),_c('td',{class:("text-right " + (item.percent_change_24h < 0
                      ? 'red--text'
                      : 'green--text'))},[_vm._v(" "+_vm._s(item.percent_change_24h > 0 ? _vm.helper.convertToBigNumber( item.percent_change_24h, 2 ) : _vm.helper.convertToBigNumber( item.percent_change_24h * -1, 2 ))+"% ")]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"d-contents"},[_vm._v(" "+_vm._s(_vm.helper.abbreviateNumber(item.volume_24h))+" ")])])])]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{staticClass:"py-4"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(("" + (_vm.$t("TopLosers")))))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.loserHeader,"items":_vm.getTopLoosers,"loading":_vm.mutableLoading,"headers-length":12,"header-props":{ sortIcon: 'mdi-chevron-up' },"no-data-text":"No Record Found.","loader-height":1,"item-key":"cmc_rank","hide-default-footer":"","disable-pagination":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(item.looser_rank))])]),_c('td',{staticClass:"text-center currency-details sticky secondary--text pointer",on:{"click":function($event){return _vm.onCurrencyView(item)}}},[_c('div',{staticClass:"d-flex pt-2"},[_c('div',[(item.logo === null)?_c('img',{staticClass:"currency-logo",attrs:{"alt":item.name,"src":require("@/assets/images/no-image.png")}}):_c('img',{staticClass:"currency-logo",attrs:{"alt":item.name,"lazy-src":item.logo,"src":item.logo},on:{"error":_vm.helper.imgerror}})]),_c('div',{staticClass:"ml-2"},[_c('span',[_vm._v(_vm._s(item.name))])])])]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"ml-1 greyText--text"},[_c('small',[_vm._v(_vm._s(item.symbol))])])]),_c('td',{staticClass:"text-right"},[(!_vm.mutableLoading)?_c('v-icon',{class:("mt-m-1px grey--text icon-" + (_vm.selected_currency.toLowerCase())),attrs:{"x-small":""}}):_vm._e(),(!_vm.mutableLoading)?_c('span',[_vm._v(_vm._s(_vm.helper.roundDecimalPlaces(item.price)))]):_c('span',[_vm._v("0.00")])],1),_c('td',{class:("text-right " + (item.percent_change_24h < 0
                      ? 'red--text'
                      : 'green--text'))},[_vm._v(" "+_vm._s(item.percent_change_24h > 0 ? _vm.helper.convertToBigNumber( item.percent_change_24h, 2 ) : _vm.helper.convertToBigNumber( item.percent_change_24h * -1, 2 ))+"% ")]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"d-contents"},[_vm._v(" "+_vm._s(_vm.helper.abbreviateNumber(item.volume_24h))+" ")])])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }