<template>
  <v-row dense class="mb-4 mx-1">
    <v-divider />
    <v-col cols="12">
      <v-row class="mt-3 mb-7">
        <v-col cols="12" md="6" class="d-flex flex-column py-md-0"><TopGainer /></v-col>
        <v-col cols="12" md="6" class="d-flex flex-column py-md-0"><TopLooser /></v-col>
      </v-row>
      <v-row dense align="center">
        <v-col cols="6" sm="4" md="2" lg="1">
          <v-btn
            block
            class=" text-capitalize body-2 no-hover-bg"
            @click="loadWatchlist"
          >
            <v-icon
              v-if="!isWatchlistLoaded"
              small
              class="icon-star-outline"
            />
            <v-icon v-else small color="accent" class="icon-star" />
            <span>{{ $t('Watchlist') }}</span>
          </v-btn>
        </v-col>
        <v-col cols="6" sm="8" md="2" lg="4">
          <div class="d-none d-lg-flex">
            <v-btn-toggle
              v-model="isMarket"
              class="fill-width d-flex v-btn-group v-btn-group--4"
              mandatory
              dense
            >
              <v-btn
                v-for="(item, idx) in btnTabs"
                :key="idx"
                :ripple="false"
                :value="item.value"
                class="flex-grow-1 text-capitalize body-2 no-hover-bg px-xs-n2"
                @click="onChangeTabMenu(item)"
              >
                {{ item.name }}
              </v-btn>
            </v-btn-toggle>
          </div>
          <div class="d-flex d-lg-none">
            <Menu
              :button-size="`primary no-hover-bg uncapitalize`"
              :selected-text="selected_tab.name"
              :list-items="btnTabs"
              key-item="name"
              @click="onChangeTabMenu($event)"
            />
          </div>
        </v-col>
        <v-col
          cols="8"
          sm="8"
          md="4"
          lg="4"
        >
          <v-text-field
            v-model="search"
            type="text"
            :placeholder="$t('Search')"
            :full-width="true"
            :disabled="isPaginateDisabled"
            hide-details
            dense
            filled
            @keydown="onKeyUpSearch"
          />
        </v-col>
        <v-col cols="4" sm="4" md="1" lg="1">
          <v-btn
            block
            class="primary  text-capitalize body-2  no-hover-bg"
            :disabled="isPaginateDisabled"
            @click="onFilter()"
          >
            <v-icon x-small :class="`${ isFilterEnabled ? 'icon-filter-on' : 'icon-filter'}`" />
            <span class="d-none d-lg-flex ml-1">{{ $t('Filters') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="3"
          lg="2"
        >
          <v-row dense align="center">
            <v-col cols="4" lg="4" class="text-right">
              <small class="grey--text">{{ $t('Rows') }}</small>
            </v-col>
            <v-col cols="4">
              <Menu
                :selected-text="`${rowsPerPage.toString()}`"
                :list-items="rows"
                :is-disabled="isPaginateDisabled"
                :button-size="`common_background`"
                @click="onChangeRowsPerPage($event)"
              />
            </v-col>
            <v-col cols="2" lg="2" >
              <v-btn
                block
                :value="true"
                class="float-right no-hover-bg"
                :disabled="isPaginateDisabled"
                @click="onPreviousPage"
              >
                <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
              </v-btn>
            </v-col>
            <v-col cols="2" lg="2">
              <v-btn
                block
                :value="false"
                class="float-right no-hover-bg"
                :disabled="isPaginateDisabled"
                @click="onNextPage"
              >
                <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="isFilterEnabled"
        dense
      >
        <v-col cols="12" sm="3" md="2" lg="2">
          <Menu
            :selected-text="$t('Categories')"
            :list-items="categories"
            key-item="dropdown_values"
            :has-check-box="true"
            :button-size="`common_background caption uncapitalize`"
            :is-disabled="isPaginateDisabled"
            @click="onSelectCategory($event)"
          />
        </v-col>
        <v-col cols="12" sm="3" md="2" lg="2">
          <Menu
            :selected-text="$t('SubCategories')"
            :list-items="subcategories"
            key-item="dropdown_values"
            :has-check-box="true"
            :button-size="`common_background caption uncapitalize`"
            :is-disabled="isPaginateDisabled"
            @click="onSelectSubCategory($event)"
          />
        </v-col>
        <v-col cols="12" sm="3" md="2" lg="1">
          <Menu
            :selected-text="$t('Chains')"
            :list-items="chains"
            key-item="dropdown_values"
            :has-check-box="true"
            :button-size="`common_background caption uncapitalize`"
            :is-disabled="isPaginateDisabled"
            @click="onSelectChain($event)"
          />
        </v-col>
        <v-col cols="12" sm="3" md="1">
          <v-btn
            block
            class="px-n3 flex-grow-1 text-capitalize caption no-hover-bg"
            :disabled="isPaginateDisabled"
            @click="removeFilter"
          >
            <v-icon
              small
            > mdi-delete-forever
            </v-icon>
            <span class="d-none d-lg-flex">{{ $t('Clear') }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col v-if="isMarket === 'exchanges'">
          <v-data-table
            :headers="headerExchange"
            :items="getExchangeVolume"
            :loading="exchangesLoading"
            :headers-length="5"
            :header-props="{ sortIcon: 'mdi-chevron-up' }"
            no-data-text="No Record Found."
            :loader-height="1"
            item-key="cmc_rank"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center">
                  <span>{{ item.rank }}</span>
                </td>
                <td
                  class="text-left sticky secondary--text"
                >
                  <v-btn
                    :href="item.url"
                    target="_blank"
                    class="text-capitalize no-spacing mr-2 elevation-0 no-hover-bg no-background"
                    :alt="$t('Website')"
                  >
                    <div>
                      <img
                        v-if="item.image === null"
                        :alt="item.name"
                        :src="require(`@/assets/images/no-image.png`)"
                        class="currency-logo"
                      />
                      <img
                        v-else
                        :alt="item.name"
                        :lazy-src="item.image"
                        :src="item.image"
                        class="currency-logo"
                        @error="helper.imgerror"
                      />
                    </div>
                    <div class="ml-2">
                      <span>{{ item.name }}</span>
                    </div>
                  </v-btn>
                </td>
                <td class="text-right">
                  <span>{{ item.trade_volume_24h_btc }}</span>
                </td>
                <td class="text-center">
                  <span>{{ item.year_established }}</span>
                </td>
                <td>
                  <span>{{ item.country }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col v-else-if="isMarket === 'market' || isMarket === 'alltime'">
          <v-data-table
            :headers="isMarket === 'market' ? header1 : header2"
            :items="isMarket === 'market' ? overview : getAllTime"
            :loading="isMarket === 'market' ? marketLoading : alltimeLoading"
            :headers-length="12"
            :header-props="{ sortIcon: 'mdi-chevron-up' }"
            no-data-text="No Record Found."
            :loader-height="1"
            item-key="cmc_rank"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
          >
            <template v-if="isMarket==='market'" v-slot:item="{ item }">
              <tr>
                <td class="text-center">
                  <v-btn x-small icon class="no-bg" @click="isWatchlistOpened(item)">
                    <v-icon v-if="item.watchlist" color="accent" class="icon-star" />
                    <v-icon v-else class="icon-star-outline" />
                  </v-btn>
                </td>
                <td class="text-center currency-details">
                  <span>{{ item.cmc_rank }}</span>
                </td>
                <td
                  class="text-left currency-details sticky secondary--text"
                  @click="onCurrencyView(item)"
                >
                  <div class="d-flex pt-2">
                    <div>
                      <img
                        v-if="item.logo === null"
                        :alt="item.name"
                        :src="require(`@/assets/images/no-image.png`)"
                        class="currency-logo"
                      />
                      <img
                        v-else
                        :alt="item.name"
                        :lazy-src="item.logo"
                        :src="item.logo"
                        class="currency-logo"
                        @error="helper.imgerror"
                      />
                    </div>
                    <div class="ml-2">
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div class="ml-1 greyText--text">
                    <small>{{ item.symbol }}</small>
                  </div>
                </td>
                <td class="text-right">
                  <v-icon v-if="!marketLoading" x-small :class="`mt-m-1px grey--text icon-${selected_currency.toLowerCase()}`"/>
                  <span v-if="!marketLoading">{{ helper.roundDecimalPlaces(item.price) }}</span>
                  <span v-else>0.00</span>
                </td>
                <td
                  :class="
                    `text-right ${
                      item.percent_change_24h < 0
                        ? 'red--text'
                        : 'green--text'
                    }`
                  "
                >
                  {{
                    item.percent_change_24h > 0
                      ? helper.convertToBigNumber(
                        item.percent_change_24h,
                        2
                      )
                      : helper.convertToBigNumber(
                        item.percent_change_24h * -1,
                        2
                      )
                  }}%
                </td>
                <td
                  :class="
                    `text-right ${
                      item.percent_change_7d < 0
                        ? 'red--text'
                        : 'green--text'
                    }`
                  "
                >
                  {{
                    item.percent_change_7d > 0
                      ? helper.convertToBigNumber(
                        item.percent_change_7d,
                        2
                      )
                      : helper.convertToBigNumber(
                        item.percent_change_7d * -1,
                        2
                      )
                  }}%
                </td>
                <td
                  :class="
                    `text-right ${
                      item.percent_change_30d < 0
                        ? 'red--text'
                        : 'green--text'
                    }`
                  "
                >
                  {{
                    item.percent_change_30d > 0
                      ? helper.convertToBigNumber(
                        item.percent_change_30d,
                        2
                      )
                      : helper.convertToBigNumber(
                        item.percent_change_30d * -1,
                        2
                      )
                  }}%
                </td>
                <td class="text-right">
                  {{ helper.abbreviateNumber(item.market_cap) }}
                </td>
                <td class="text-right">
                  <span class="d-contents">
                    {{ helper.abbreviateNumber(item.volume_24h) }}
                  </span>
                </td>
                <td class="text-right">
                  <span class="d-contents">
                    {{ helper.abbreviateNumber(item.circulating_supply) }}
                  </span>
                </td>
                <td class="text-right">
                  <span class="d-contents">
                    {{ helper.abbreviateNumber(item.total_supply) }}
                  </span>
                </td>
              </tr>
            </template>
            <template v-else v-slot:item="{ item }">
              <tr>
                <td class="text-center currency-details">
                  <span>{{ item.cmc_rank }}</span>
                </td>
                <td
                  class="text-left currency-details sticky secondary--text"
                  @click="onCurrencyView(item)"
                >
                  <div class="d-flex pt-2">
                    <div>
                      <img
                        v-if="item.logo === null"
                        :alt="item.name"
                        :src="require(`@/assets/images/no-image.png`)"
                        class="currency-logo"
                      />
                      <img
                        v-else
                        :alt="item.name"
                        :lazy-src="item.logo"
                        :src="item.logo"
                        class="currency-logo"
                        @error="helper.imgerror"
                      />
                    </div>
                    <div class="ml-2">
                      <span>{{ helper.capitalizeFirstLetter(item.name) }}</span>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div class="ml-1 greyText--text">
                    <small>{{ item.symbol }}</small>
                  </div>
                </td>
                <td class="text-right">
                  <v-icon v-if="!alltimeLoading" x-small :class="`mt-m-1px grey--text icon-${selected_currency.toLowerCase()}`"/>
                  <span v-if="!alltimeLoading">{{ helper.roundDecimalPlaces(item.price) }}</span>
                  <span v-else>0.00</span>
                </td>
                <td class="text-right">
                  <v-icon v-if="!alltimeLoading" x-small :class="`mt-m-1px red--text icon-${selected_currency.toLowerCase()}`"/>
                  <span v-if="!alltimeLoading" class="red--text">{{ helper.roundDecimalPlaces(item.alltime_low) }}</span>
                  <span v-else class="red--text">0.00</span>
                </td>
                <td class="text-right">{{ helper.utcToDate(new Date(item.alltime_low_date)) }}</td>
                <td class="text-right">
                  <v-icon v-if="!alltimeLoading" x-small :class="`mt-m-1px green--text icon-${selected_currency.toLowerCase()}`"/>
                  <span v-if="!alltimeLoading" class="green--text">{{ helper.roundDecimalPlaces(item.alltime_high) }}</span>
                  <span v-else class="green--text">0.00</span>
                </td>
                <td class="text-right">{{ helper.utcToDate(new Date(item.alltime_high_date)) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col v-else>
          <TableGainerLoser :loading="gainerloserLoading"/>
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="my-1" dense>
        <v-col
          cols="12"
          offset-md="9"
          md="3"
          offset-lg="10"
          lg="2"
        >
          <v-row dense align="center">
            <v-col cols="4" lg="4" class="text-right">
              <small class="grey--text">{{ $t('Rows') }}</small>
            </v-col>
            <v-col cols="4" class="">
              <Menu
                :selected-text="`${rowsPerPage.toString()}`"
                :list-items="rows"
                :is-disabled="isPaginateDisabled"
                :button-size="`common_background`"
                @click="onChangeRowsPerPage($event)"
              />
            </v-col>
            <v-col cols="2" lg="2" >
              <v-btn
                block
                :value="true"
                class="float-right no-hover-bg"
                :disabled="isPaginateDisabled"
                @click="onPreviousPage"
              >
                <v-icon small class="px-1 grey--text icon-arrow-head-left"/>
              </v-btn>
            </v-col>
            <v-col cols="2" lg="2">
              <v-btn
                block
                :value="false"
                class="float-right no-hover-bg"
                :disabled="isPaginateDisabled"
                @click="onNextPage"
              >
                <v-icon small class="px-1 grey--text icon-arrow-head-right"/>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider />
    </v-col>

    <SigninDialog
      :dialog="dialog"
      :header-text="dialogHeaderText"
      :body-text="dialogBodyText"
      @close="dialog=false"
    />

    <SnackBar :snackbar="snackbar" :color="color" :error="error" @close="snackbar=false"/>

  </v-row>
</template>

<script>
import {
  OVERVIEW_REQUEST,
  DROPDOWN_REQUEST,
  POST_WATCHLIST_REQUEST,
  DELETE_WATCHLIST_REQUEST,
  GET_WATCHLIST_IDS_REQUEST,
  GET_PRICE_GAINERS_REQUEST,
  GET_PRICE_LOOSERS_REQUEST,
  GET_ALL_TIME_REQUEST,
  GET_EXCHANGE_VOLUME_REQUEST,
} from '@/store/overview/action'
import helper from '@/utils/helper'
import _ from 'lodash'
import { get, call, commit, dispatch } from 'vuex-pathify'
import Menu from '@/components/atoms/Menu'
import TopGainer from '@/components/shared/TopGainer'
import TopLooser from '@/components/shared/TopLooser'
import SigninDialog from '@/components/shared/SigninDialog'
import SnackBar from '@/components/shared/SnackBar'
import TableGainerLoser from '@/components/organisms/overview/TableGainerLoser'
import firebase from 'firebase'
import { mapMutations } from 'vuex'
import {
  LOGOUT,
} from '@/store/account/action'

export default {
  name: 'Overview',
  components: {
    Menu,
    TopGainer,
    TopLooser,
    TableGainerLoser,
    SigninDialog,
    SnackBar,
  },
  data: function () {
    return {
      helper: helper,
      dialog: false,
      dialogHeaderText: '',
      dialogBodyText: '',
      isWatchlistLoaded: false,
      marketLoading: true,
      alltimeLoading: true,
      exchangesLoading: true,
      gainerloserLoading: true,
      timeout: null,
      currTimeout: null,
      currency: { value: 'USD', currency: 'USD' },
      currencies: [
        { value: 'USD', currency: 'USD' },
        { value: 'EUR', currency: 'EUR' },
        { value: 'INR', currency: 'INR' },
      ],
      search: '',
      isMarket: ['market','alltime','exchanges'],
      next: true,
      prev: false,
      rows: [100, 200, 300, 400],
      rowsPerPage: 100,
      marketPage: 1,
      alltimePage: 1,
      exchangesPage: 1,
      gainerloserPage: 1,
      selected_category: 'ALL',
      selected_subcategory: 'ALL',
      selected_chain: 'ALL',
      selected_tab: { name: this.$i18n.t('Market'), value: 'market' },
      error: null,
      snackbar: false,
      color: 'success',
      isPaginateDisabled: false,
      btnTabs: [
        { name: this.$i18n.t('Market'), value: 'market' },
        { name: this.$i18n.t('AllTime'), value: 'alltime' },
        { name: this.$i18n.t('GainersLosers'), value: 'gainerloser' },
        { name: this.$i18n.t('Exchanges'), value: 'exchanges' },
      ],
      header1: [
        {
          text: ' ',
          value: 'watchlist',
          align: 'center',
          width: '1%',
          sortable: false,
          fixed: true,
        },
        {
          text: 'Rank',
          value: 'cmc_rank',
          align: 'center',
          width: '2%',
          sortable: true,
          fixed: true,
        },
        {
          text: 'Coin',
          value: 'Name',
          align: 'left',
          width: '12%',
          sortable: true,
          fixed: true,
          class: 'sticky',
        },
        {
          text: '',
          value: 'symbol',
          align: 'left',
          width: '3%',
          sortable: true,
          fixed: true,
        },
        {
          text: 'Price',
          value: 'price',
          align: 'right',
          width: '15%',
          sortable: true,
        },
        {
          text: '24h',
          value: 'percent_change_24h',
          align: 'right',
          width: '10%',
          sortable: true,
        },
        {
          text: '7d',
          value: 'percent_change_7d',
          align: 'right',
          width: '10%',
          sortable: true,
        },
        {
          text: '30d',
          value: 'percent_change_30d',
          align: 'right',
          width: '10%',
          sortable: true,
        },
        {
          text: 'MarketCap',
          value: 'market_cap',
          align: 'right',
          width: '10%',
          sortable: true,
        },
        {
          text: 'Volume',
          value: 'volume_24h',
          align: 'right',
          width: '9%',
          sortable: false,
        },
        {
          text: 'Circulating',
          value: 'circulating_supply',
          align: 'center',
          width: '9%',
          sortable: false,
        },
        {
          text: 'Total',
          value: 'total_supply',
          align: 'center',
          width: '9%',
          sortable: false,
        },
      ],
      header2: [
        {
          text: 'Rank',
          value: 'cmc_rank',
          align: 'center',
          width: '3%',
          sortable: true,
        },
        {
          text: 'Coin',
          value: 'name',
          align: 'left',
          width: '12%',
          sortable: true,
          class: 'sticky',
        },
        {
          text: '',
          value: 'symbol',
          align: 'left',
          width: '3%',
          sortable: true,
          fixed: true,
        },
        {
          text: 'Price',
          value: 'price',
          align: 'right',
          width: '15%',
          sortable: true,
        },
        {
          text: 'All Time Low',
          align: 'right',
          width: '17%',
          sortable: true,
        },
        {
          text: 'All Time Low Date',
          align: 'right',
          width: '17%',
          sortable: true,
        },
        {
          text: 'All Time High',
          align: 'right',
          width: '17%',
          sortable: true,
        },
        {
          text: 'All Time High Date',
          align: 'right',
          width: '17%',
          sortable: true,
        },
      ],
      headerExchange: [
        {
          text: 'Rank',
          value: 'rank',
          align: 'center',
          width: '2%',
          sortable: true,
        },
        {
          text: 'Name',
          value: 'name',
          align: 'left',
          width: '30%',
          sortable: true,
        },
        {
          text: '24h BTC Trade Volume',
          value: 'name',
          align: 'right',
          width: '28%',
          sortable: true,
        },
        {
          text: 'Year Established',
          value: 'year_established',
          align: 'center',
          width: '20%',
          sortable: true,
        },
        {
          text: 'Country',
          value: 'country',
          align: 'left',
          width: '20%',
          sortable: true,
        },
      ],
      user: {},
      isFilterEnabled: false,
      days: '1D',
    }
  },
  computed: {
    ...get('overview', ['overview', 'watchlistIds', 'getAllTime', 'getExchangeVolume']),
    selected_currency: get('getGlobalFiat'),
    categories: {
      get: get('overview/categories'),
      set: (value) => commit('overview/SET_CATEGORY_ITEMS', value),
    },
    chains: {
      get: get('overview/chains'),
      set: (value) => commit('overview/SET_CHAIN_ITEMS', value),
    },
    subcategories: {
      get: get('overview/subcategories'),
      set: (value) => commit('overview/SET_SUB-CATEGORY_ITEMS', value),
    },
  },
  watch: {
    '$store.state.fiat': {
      handler(value) {
        this.onSelectCurrency(value)
      },
      deep: true,
      immediate: true,
    },
  },
  async beforeMount() {
    this.user = firebase.auth().currentUser
    if (this.user !== null && this.user.emailVerified) {
      await this.getWatchListIds()
    }
    await this.onPopulateDropdowns()
    await this.onFetchExchangeVolume()
    this.currTimeout = setInterval(async () => {
      await this.onFetchCurrencies()
      await this.onFetchAllTime()
      await this.onFetchExchangeVolume()
    }, 5 * 60 * 1000)
  },
  beforeDestroy() {
    clearTimeout(this.currTimeout)
  },
  methods: {
    ...call('overview', ['GET_WATCHLIST_IDS_REQUEST']),
    ...call('account', ['LOGOUT']),
    ...mapMutations('account', ['SET_VERIFIED_EMAILUSER']),
    onChangeTabMenu(item) {
      this.selected_tab = item
      this.isMarket = item.value
      this.isPaginateDisabled = item.value !== 'market' ? true : false
    },
    async onFetchExchangeVolume() {
      this.exchangesLoading = true
      const payload = {
        name: this.search,
        rows: this.search ? 100000 : this.rowsPerPage,
        page: this.exchangesPage,
      }

      await this.loadExchangeVolumeList(payload)
      this.exchangesLoading = false
    },
    async onFetchAllTime() {
      this.alltimeLoading = true

      let categories = ''
      let chains = ''

      _.forEach(this.categories, (o) => {
        categories += o.selected ? `${o.dropdown_values},` : ''
      })

      _.forEach(this.chains, (o) => {
        chains += o.selected ? `${o.dropdown_values},` : ''
      })

      categories = categories.substring(0, categories.length - 1)
      chains = chains.substring(0, chains.length - 1)

      const payload = {
        name: this.search,
        currency: this.selected_currency,
        rows: this.search ? 100000 : this.rowsPerPage,
        page: this.alltimePage,
        sort: 'asc',
        category:
          categories.toUpperCase() === 'ALL' || _.isEmpty(categories)
            ? null
            : categories,
        chain:
          chains.toUpperCase() === 'ALL' || _.isEmpty(chains) ? null : chains,
      }

      await this.loadAllTimeList(payload)
      this.alltimeLoading = false
    },
    onTopGainerLoser() {
      this.isPaginateDisabled = true
      this.isMarket = 'gainerloser'
    },
    async removeFilter() {
      this.search = ''
      await this.onPopulateDropdowns()
      await this.onFetchCurrencies()
    },
    onFilter() {
      this.isFilterEnabled = !this.isFilterEnabled
    },
    async getWatchList() {
      commit('overview/SET_WATCHLIST_IDS', this.watchlistIds)
    },
    async getWatchListIds() {
      const currentUser = await firebase.auth().currentUser

      if (currentUser && currentUser.emailVerified) {
        this.SET_VERIFIED_EMAILUSER(currentUser.emailVerified)
        await dispatch('overview/' + GET_WATCHLIST_IDS_REQUEST).then()
          .catch((error) => {
            console.log(error,'error')
            this.LOGOUT()
            this.$router.go({ name: 'Home' })
          })
      }
    },
    async isWatchlistOpened({ name, id, watchlist }) {
      const isWatchListOpened = localStorage.getItem('isWatchListOpened')

      if (isWatchListOpened === null && this.user === null) {
        this.dialogHeaderText = this.$t('Watchlist')
        this.dialogBodyText = this.$t('WatchlistUpdateInfo')
        this.dialog = true
      } else if (this.user !== null && this.user.email) {
        if (!watchlist) {
          await dispatch('overview/' + POST_WATCHLIST_REQUEST, id)
            .then(async (resp) => {
              this.snackbar = true
              this.color = 'success'
              this.error = name + ' successfully added to watchlist'
              this.getWatchListIds()
            })
            .catch((error) => {
              this.snackbar = true
              this.color = 'error'
              this.error = name + ': ' + error.detail
            })
        } else {
          await dispatch('overview/' + DELETE_WATCHLIST_REQUEST, id)
            .then(async (resp) => {
              this.snackbar = true
              this.color = 'primary'
              this.error = name + ' successfully removed from watchlist'
              this.getWatchListIds()
            })
            .catch((error) => {
              this.snackbar = true
              this.color = 'error'
              this.error = name + ': ' + error.detail
            })
        }
      } else {
        this.$router.push({ path: '/account' })
      }
    },
    loadWatchlist() {
      if (this.user !== null && this.user.emailVerified) {
        this.isWatchlistLoaded = !this.isWatchlistLoaded
        this.onFetchCurrencies()
      } else {
        const isWatchListOpened = localStorage.getItem('isWatchListOpened')

        if (isWatchListOpened === null && this.user === null) {
          this.dialog = true
        } else {
          this.$router.push({ path: '/account' })
        }
      }
    },
    onPaginate() {
      const selTemp = this.selected_tab.value

      if (selTemp === 'market')
        this.onFetchCurrencies()
      else if (selTemp === 'alltime')
        this.onFetchAllTime()
      else
        this.onFetchExchangeVolume()
    },
    onPreviousPage() {
      if (this[`${this.selected_tab.value}Page`] <= 1) {
        this[`${this.selected_tab.value}Page`] = 1
      } else {
        this[`${this.selected_tab.value}Page`] -= 1
      }
      this.onPaginate()
    },
    onNextPage() {
      this[`${this.selected_tab.value}Page`] += 1
      this.onPaginate()
    },
    onChangeRowsPerPage(row) {
      this.rowsPerPage = row
      this[`${this.selected_tab.value}Page`] = 1
      this.onPaginate()
    },
    addSelected(obj, item) {
      const idx = _.findIndex(this.$store.state.overview[obj], (o) => {
        return o.dropdown_values === item.dropdown_values
      })

      if (item.dropdown_values === 'ALL') {
        this.$store.state.overview[obj].forEach((el, index) => {
          commit(`overview/SET_SELECTED_${obj.toUpperCase()}_ITEMS`, {
            index: index,
            value: index === 0 ? true : false,
          })
        })
      } else {
        commit(`overview/SET_SELECTED_${obj.toUpperCase()}_ITEMS`, { index: 0, value: false })
        commit(`overview/SET_SELECTED_${obj.toUpperCase()}_ITEMS`, { index: idx, value: !item.selected })

        let hasSelection = false

        this.$store.state.overview[obj].forEach((el, index) => {
          if (el.selected) hasSelection = true

          return
        })

        if (!hasSelection) commit(`overview/SET_SELECTED_${obj.toUpperCase()}_ITEMS`, { index: 0, value: true })

      }

      this[obj] = Object.assign([], this.$store.state.overview[obj])
    },
    onSelectCategory(item) {
      this.selected_category = item
      this.addSelected('categories', item)
      this.onFetchCurrencies()
    },
    onSelectChain(item) {
      this.selected_chain = item
      this.addSelected('chains', item)
      this.onFetchCurrencies()
    },
    onSelectSubCategory(item) {
      this.addSelected('subcategories', item)
      this.onFetchCurrencies()
    },
    onSelectCurrency(item) {
      this.onFetchCurrencies()
      this.onFetchAllTime()
    },
    onCurrencyView(item) {
      this.$router.push({
        name: 'CurrencyDetails',
        path: `/currencies/${item.id}`,
        params: {
          name: item.id,
          id: item.id,
          days: this.days,
          currency: item.currency,
          coin: item.slug,
        },
      })
    },
    onKeyUpSearch() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {

        if (this.isMarket === 'market')
          this.onFetchCurrencies()
        else if (this.isMarket === 'alltime')
          this.onFetchAllTime()
        else
          this.onFetchExchangeVolume()
      }, 600)
    },
    async onFetchGainerLoser() {
      this.gainerloserLoading = true
      const payload = {
        rows: this.search ? 100000 : this.rowsPerPage,
        page: this.gainerloserPage,
        currency: this.selected_currency,
      }

      await this.loadTopGainerList(payload)
      await this.loadTopLooserList(payload)
      this.gainerloserLoading = false
    },
    async onPopulateDropdowns(key) {
      await this.loadDropdown({
        type: 'CATEGORY',
      })
      await this.loadDropdown({
        type: 'CHAIN',
      })
      await this.loadDropdown({
        type: 'SUB-CATEGORY',
      })
    },
    async onFetchCurrencies() {
      let categories = ''
      let chains = ''
      let subcat = ''

      _.forEach(this.categories, (o) => {
        categories += o.selected ? `${o.dropdown_values},` : ''
      })

      _.forEach(this.chains, (o) => {
        chains += o.selected ? `${o.dropdown_values},` : ''
      })

      _.forEach(this.subcategories, (o) => {
        subcat += o.selected ? `${o.dropdown_values},` : ''
      })

      categories = categories.substring(0, categories.length - 1)
      chains = chains.substring(0, chains.length - 1)
      subcat = subcat.substring(0, subcat.length - 1)

      const payload = {
        description: this.search,
        currency: this.selected_currency,
        rows: this.search ? 100000 : this.rowsPerPage,
        page: this.marketPage,
        sort: 'asc',
        category:
          categories.toUpperCase() === 'ALL' || _.isEmpty(categories)
            ? null
            : categories,
        sub_category:
          subcat.toUpperCase() === 'ALL' || _.isEmpty(subcat) ? null : subcat,
        chain:
          chains.toUpperCase() === 'ALL' || _.isEmpty(chains) ? null : chains,
      }

      if (this.isWatchlistLoaded) {
        payload.is_watchlist = this.isWatchlistLoaded,
        payload.user_token = JSON.parse(localStorage.getItem('token'))
      }
      this.marketLoading = true
      await this.load(payload)
      await this.getWatchList()
      this.marketLoading = false
    },
    load: call(`overview/${OVERVIEW_REQUEST}`),
    loadDropdown: call(`overview/${DROPDOWN_REQUEST}`),
    loadTopGainerList: call(`overview/${GET_PRICE_GAINERS_REQUEST}`),
    loadTopLooserList: call(`overview/${GET_PRICE_LOOSERS_REQUEST}`),
    loadAllTimeList: call(`overview/${GET_ALL_TIME_REQUEST}`),
    loadExchangeVolumeList: call(`overview/${GET_EXCHANGE_VOLUME_REQUEST}`),
  },
}
</script>

<style lang="scss" scoped>
.currency-logo {
  height: 25px;
}

.currency-details {
  &:hover {
    cursor: pointer;
  }
}

.snackbar-icon {
  float: right;
  padding: 5px 0;
}
</style>
