<template>
  <v-row dense>
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col cols="12" md="6">
      <v-row>
        <v-col class="py-4">
          <span class="font-weight-bold">{{ `${$t("TopGainers")}` }}</span>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-data-table
            :headers="gainerHeader"
            :items="getTopGainers"
            :loading="mutableLoading"
            :headers-length="12"
            :header-props="{ sortIcon: 'mdi-chevron-up' }"
            no-data-text="No Record Found."
            :loader-height="1"
            item-key="cmc_rank"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center"><span>{{ item.gainer_rank }}</span></td>
                <td
                  class="text-center currency-details sticky secondary--text pointer"
                  @click="onCurrencyView(item)"
                >
                  <div class="d-flex pt-2">
                    <div>
                      <img
                        v-if="item.logo === null"
                        :alt="item.name"
                        :src="require(`@/assets/images/no-image.png`)"
                        class="currency-logo"
                      />
                      <img
                        v-else
                        :alt="item.name"
                        :lazy-src="item.logo"
                        :src="item.logo"
                        class="currency-logo"
                        @error="helper.imgerror"
                      />
                    </div>
                    <div class="ml-2">
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div class="ml-1 greyText--text">
                    <small>{{ item.symbol }}</small>
                  </div>
                </td>
                <td class="text-right">
                  <v-icon v-if="!mutableLoading" x-small :class="`mt-m-1px grey--text icon-${selected_currency.toLowerCase()}`"/>
                  <span v-if="!mutableLoading">{{ helper.roundDecimalPlaces(item.price) }}</span>
                  <span v-else class="">0.00</span>
                </td>
                <td
                  :class="
                    `text-right ${
                      item.percent_change_24h < 0
                        ? 'red--text'
                        : 'green--text'
                    }`
                  "
                >
                  {{
                    item.percent_change_24h > 0
                      ? helper.convertToBigNumber(
                        item.percent_change_24h,
                        2
                      )
                      : helper.convertToBigNumber(
                        item.percent_change_24h * -1,
                        2
                      )
                  }}%
                </td>
                <td class="text-right">
                  <span class="d-contents">
                    {{ helper.abbreviateNumber(item.volume_24h) }}
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="6">
      <v-row>
        <v-col class="py-4">
          <span class="font-weight-bold">{{ `${$t("TopLosers")}` }}</span>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-data-table
            :headers="loserHeader"
            :items="getTopLoosers"
            :loading="mutableLoading"
            :headers-length="12"
            :header-props="{ sortIcon: 'mdi-chevron-up' }"
            no-data-text="No Record Found."
            :loader-height="1"
            item-key="cmc_rank"
            hide-default-footer
            disable-pagination
            mobile-breakpoint="0"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-center"><span>{{ item.looser_rank }}</span></td>
                <td
                  class="text-center currency-details sticky secondary--text pointer"
                  @click="onCurrencyView(item)"
                >
                  <div class="d-flex pt-2">
                    <div>
                      <img
                        v-if="item.logo === null"
                        :alt="item.name"
                        :src="require(`@/assets/images/no-image.png`)"
                        class="currency-logo"
                      />
                      <img
                        v-else
                        :alt="item.name"
                        :lazy-src="item.logo"
                        :src="item.logo"
                        class="currency-logo"
                        @error="helper.imgerror"
                      />
                    </div>
                    <div class="ml-2">
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div class="ml-1 greyText--text">
                    <small>{{ item.symbol }}</small>
                  </div>
                </td>
                <td class="text-right">
                  <v-icon v-if="!mutableLoading" x-small :class="`mt-m-1px grey--text icon-${selected_currency.toLowerCase()}`"/>
                  <span v-if="!mutableLoading">{{ helper.roundDecimalPlaces(item.price) }}</span>
                  <span v-else>0.00</span>
                </td>
                <td
                  :class="
                    `text-right ${
                      item.percent_change_24h < 0
                        ? 'red--text'
                        : 'green--text'
                    }`
                  "
                >
                  {{
                    item.percent_change_24h > 0
                      ? helper.convertToBigNumber(
                        item.percent_change_24h,
                        2
                      )
                      : helper.convertToBigNumber(
                        item.percent_change_24h * -1,
                        2
                      )
                  }}%
                </td>
                <td class="text-right">
                  <span class="d-contents">
                    {{ helper.abbreviateNumber(item.volume_24h) }}
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { get, call, commit, dispatch } from 'vuex-pathify'
import helper from '@/utils/helper'

export default {
  props: {
    'loading': { type: Boolean, default: () => false },
  },
  data:function () {
    return {
      days: '1D',
      helper: helper,
      mutableLoading: this.loading,
      gainerHeader: [
        {
          text: 'Rank',
          value: 'gainer_rank',
          align: 'center',
          width: '5%',
          sortable: true,
          fixed: true,
        },
        {
          text: 'Coin',
          value: 'Name',
          align: 'left',
          width: '25%',
          sortable: true,
          fixed: true,
          class: 'sticky',
        },
        {
          text: '',
          value: 'symbol',
          align: 'left',
          width: '10%',
          sortable: true,
          fixed: true,
        },
        {
          text: 'Price',
          value: 'price',
          align: 'right',
          width: '20%',
          sortable: true,
        },
        {
          text: '24h',
          value: 'percent_change_24h',
          align: 'right',
          width: '20%',
          sortable: true,
        },
        {
          text: 'Volume',
          value: 'volume_24h',
          align: 'right',
          width: '20%',
          sortable: false,
        },
      ],
      loserHeader: [
        {
          text: 'Rank',
          value: 'looser_rank',
          align: 'center',
          width: '5%',
          sortable: true,
          fixed: true,
        },
        {
          text: 'Coin',
          value: 'Name',
          align: 'left',
          width: '25%',
          sortable: true,
          fixed: true,
          class: 'sticky',
        },
        {
          text: '',
          value: 'symbol',
          align: 'left',
          width: '10%',
          sortable: true,
          fixed: true,
        },
        {
          text: 'Price',
          value: 'price',
          align: 'right',
          width: '20%',
          sortable: true,
        },
        {
          text: '24h',
          value: 'percent_change_24h',
          align: 'right',
          width: '20%',
          sortable: true,
        },
        {
          text: 'Volume',
          value: 'volume_24h',
          align: 'right',
          width: '20%',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...get('overview', ['getTopGainers', 'getTopLoosers']),
    selected_currency: get('getGlobalFiat'),
  },
  watch: {
    '$store.state.fiat': {
      handler(value) {
        this.mutableLoading = true
      },
      deep: true,
      immediate: true,
    },
    'getTopGainers': {
      handler(value) {
        this.mutableLoading = false
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onCurrencyView(item) {
      this.$router.push({
        name: 'CurrencyDetails',
        path: `/currencies/${item.id}`,
        params: {
          name: item.id,
          id: item.id,
          days: this.days,
          currency: item.currency,
          coin: item.slug,
        },
      })
    },
  },
}
</script>
<style lang="scss">
.pointer {
  cursor: pointer;
}
</style>
