<template>
  <v-card class="unique-bg elevation-0 fill-hieght flex d-flex flex-column">
    <v-card-title class="px-4 py-1">
      <h4 class="subtitle-1">
        {{ `${$t("TopLosers")}` }} <small class="grey--text mt-1">{{ `${$t("24H")}` }}</small>
      </h4>
    </v-card-title>
    <v-card-text class="px-2 py-0">
      <v-divider />
      <v-row class="pa-0 ma-0">
        <v-col
          v-for="(o, i) in getTopLoosersDetails.slice(0, 3)"
          :key="i"
          cols="12"
          sm="4"
          class="pointer"
          @click="gotoUrl(o)"
        >
          <v-row dense align="center">
            <v-col class="flex-grow-0">
              <img
                v-if="o.logo === null"
                :alt="o.name"
                :src="require(`@/assets/images/no-image.png`)"
                class="currency-logo"
              />
              <img
                v-else
                :alt="o.name"
                :lazy-src="o.logo"
                :src="o.logo"
                class="currency-logo mt-n5"
                @error="helper.imgerror"
              />
            </v-col>
            <v-col class="flex-grow-1">
              <p class="mb-0 secondary--text caption">
                {{ `${o.name}` }}
              </p>
              <p class="mb-0 caption">
                <v-icon x-small :class="`mt-m-1px grey--text icon-${selected_currency.toLowerCase()}`"/>
                <span v-if="!loading">{{ helper.roundDecimalPlaces(o.price) }}</span>
                <span v-else>0.00</span>
                <span
                  v-if="!loading"
                  :class="
                    `mb-0 ${
                      o.percent_change_24h < 0
                        ? 'red--text'
                        : 'green--text'
                    }`
                  "
                >
                  {{
                    o.percent_change_24h > 0 ? o.percent_change_24h : o.percent_change_24h * -1
                  }}%
                </span>
                <span v-else class="ml-1 red--text">0.00%</span>
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { get, call } from 'vuex-pathify'
import {
  GET_PRICE_LOOSERS_REQUEST,
} from '@/store/overview/action'
import helper from '@/utils/helper'

export default {
  name: 'TopLooser',
  data: () => ({
    helper: helper,
    loading: false,
  }),
  computed: {
    getTopLoosersDetails: get('overview/topLoosers'),
    selected_currency: get('getGlobalFiat'),
  },
  watch: {
    '$store.state.fiat': {
      handler(value) {
        this.getTopLoosers()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...call('overview',['GET_PRICE_LOOSERS_REQUEST']),
    gotoUrl(o) {
      const url = `/currencies/${o.id}`

      if (url !== this.$route.path) this.$router.push(url)
    },
    async getTopLoosers() {
      this.loading = true
      const payload = {
        rows: 30,
        currency: this.selected_currency,
      }

      await this.GET_PRICE_LOOSERS_REQUEST(payload)
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.theme--light {
  .v-card.unique-bg {
    // background-color: transparent!important
  }
}
</style>
